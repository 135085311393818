import React, { useState, useEffect, useRef } from 'react';
import { AlertTriangle, ArrowUpRight, PlusCircle, Mail, Shield, Skull } from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '../config/supabase';

const Blotter = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);

  // Fetch events from Supabase
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data, error } = await supabase
          .from('events')
          .select('*')
          .order('date', { ascending: false });

        if (error) throw error;
        setEvents(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to load data from database');
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  // Filter events based on search term
  const filteredEvents = events.filter(event => 
    event.protocol?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedRowIndex(prev => Math.min(prev + 1, filteredEvents.length - 1));
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedRowIndex(prev => Math.max(prev - 1, 0));
      } else if (e.key === 'Enter') {
        // TODO: Implement row expansion functionality
        console.log('Enter pressed on row:', filteredEvents[selectedRowIndex]);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [filteredEvents, selectedRowIndex]);

  useEffect(() => {
    if (tableRef.current && tableContainerRef.current) {
      const selectedRow = tableRef.current.querySelector(`tr:nth-child(${selectedRowIndex + 1})`);
      if (selectedRow) {
        const container = tableContainerRef.current;
        const rowTop = selectedRow.offsetTop;
        const rowBottom = rowTop + selectedRow.offsetHeight;
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.clientHeight;

        if (rowTop < containerTop) {
          container.scrollTop = rowTop;
        } else if (rowBottom > containerBottom) {
          container.scrollTop = rowBottom - container.clientHeight;
        }
      }
    }
  }, [selectedRowIndex]);

  const getEventTypeIcon = (type) => {
    const eventType = type?.toLowerCase() || '';
    
    if (eventType.includes('hack')) return <AlertTriangle size={16} className="text-red-500" />;
    if (eventType.includes('rug')) return <Skull size={16} className="text-purple-500" />;
    if (eventType.includes('enforcement')) return <Shield size={16} className="text-yellow-500" />;
    if (eventType.includes('exploit')) return <ArrowUpRight size={16} className="text-orange-500" />;
    
    return <AlertTriangle size={16} />;
  };

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };

  const handleSubmitEvent = () => {
    console.log('Submit an Event clicked');
    // TODO: Implement submit event functionality
  };

  const handleContact = () => {
    console.log('Contact clicked');
    // TODO: Implement contact functionality
  };

  // Format amount for display
  const formatAmount = (amount) => {
    if (!amount || amount === 'N/A' || amount === 'null') {
      return 'N/A';
    }
    
    // Try to parse it as a number if it's a string
    try {
      const cleaned = amount.toString().replace(/[^0-9.-]+/g, '');
      const numAmount = parseFloat(cleaned);
      return isNaN(numAmount) ? 'N/A' : `$${numAmount.toLocaleString()}`;
    } catch (e) {
      return 'N/A';
    }
  };

  if (loading) {
    return (
      <div className="blotter-container">
        <div className="blotter-content loading-content">
          <p className="loading-text">Loading data<span className="loading-dots">...</span></p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="blotter-container">
        <div className="blotter-content error-content">
          <p className="error-text">Error: {error}</p>
          <p>Please try refreshing the page.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="blotter-container">
      <div className="blotter-content">
        <h1 className="blotter-title">
          <span className="main-title">DefiDeputy</span>
          <span className="sub-title">Crime Blotter</span>
        </h1>
        
        <div className="search-container">
          <p className="search-prompt">
            {'>'} Protocol search: 
            <input 
              type="text" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
              aria-label="Search protocols"
            />
            <span className="cursor">_</span>
          </p>
        </div>
        
        <div ref={tableContainerRef} className="table-container">
          <table ref={tableRef} className="event-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Protocol</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Source</th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.map((event, index) => (
                <tr 
                  key={event.id} 
                  onClick={() => handleRowClick(index)}
                  className={index === selectedRowIndex ? 'selected-row' : ''}
                >
                  <td>{event.date}</td>
                  <td className={`event-type ${event.type}`}>
                    <div className="event-type-content">
                      {getEventTypeIcon(event.type)}
                      <span>{event.type}</span>
                    </div>
                  </td>
                  <td>
                    <Link to={`/protocol/${event.protocol.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`} className="protocol-link">
                      {event.protocol}
                    </Link>
                  </td>
                  <td>{event.description}</td>
                  <td className="amount">{formatAmount(event.amount)}</td>
                  <td>
                    {event.source_url && (
                      <a 
                        href={event.source_url} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="source-link"
                      >
                        Link
                      </a>
                    )}
                  </td>
                </tr>
              ))}
              {filteredEvents.length === 0 && (
                <tr>
                  <td colSpan="6" className="text-center">No events found matching your search.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <footer className="blotter-footer">
        <button onClick={handleSubmitEvent} className="footer-button submit-button">
          <PlusCircle size={16} />
          Submit an Event
        </button>
        <button onClick={handleContact} className="footer-button contact-button">
          <Mail size={16} />
          Contact
        </button>
        <div className="footer-info">
          <p>Displaying {filteredEvents.length} of {events.length} events</p>
        </div>
      </footer>
    </div>
  );
};

export default Blotter;