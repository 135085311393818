import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Shield, Globe, GithubIcon, TwitterIcon, DollarSign, AlertTriangle } from 'lucide-react';

const ProtocolPage = () => {
  // Removed unused id variable
  // This would come from your data source in a real app
  const protocolData = {
    name: "Example Protocol",
    type: "DEX",
    tvl: "1,234,567",
    links: {
      website: "https://example.com",
      github: "https://github.com/example",
      twitter: "https://twitter.com/example"
    },
    audits: [
      {
        firm: "Security Firm A",
        date: "2024-01-15",
        findings: "No critical issues found"
      },
      {
        firm: "Security Firm B",
        date: "2024-02-20",
        findings: "Minor issues addressed"
      }
    ],
    exploits: [
      {
        date: "2024-03-01",
        type: "hack",
        amount: "500,000",
        description: "Smart contract vulnerability exploited"
      }
    ]
  };

  return (
    <div className="protocol-container">
      <div className="protocol-content">
        <h1 className="blotter-title">
          <span className="main-title">DefiDeputy</span>
          <span className="sub-title">Crime Blotter</span>
        </h1>

        <div className="protocol-header">
          <h2 className="protocol-name">{protocolData.name}</h2>
          <Link to="/" className="back-link">← Back to Blotter</Link>
        </div>

        <div className="protocol-info">
          <div className="info-section">
            <h3>Protocol Details</h3>
            <div className="info-grid">
              <div className="info-item">
                <span className="label">Type:</span>
                <span className="value">{protocolData.type}</span>
              </div>
              <div className="info-item">
                <DollarSign className="icon" />
                <span className="label">TVL:</span>
                <span className="value">${protocolData.tvl}</span>
              </div>
            </div>
          </div>

          <div className="info-section">
            <h3>Important Links</h3>
            <div className="links-grid">
              <a href={protocolData.links.website} target="_blank" rel="noopener noreferrer" className="link-item">
                <Globe className="icon" />
                Website
              </a>
              <a href={protocolData.links.github} target="_blank" rel="noopener noreferrer" className="link-item">
                <GithubIcon className="icon" />
                GitHub
              </a>
              <a href={protocolData.links.twitter} target="_blank" rel="noopener noreferrer" className="link-item">
                <TwitterIcon className="icon" />
                Twitter
              </a>
            </div>
          </div>

          <div className="info-section">
            <h3>Security Audits</h3>
            <div className="audits-list">
              {protocolData.audits.map((audit, index) => (
                <div key={index} className="audit-item">
                  <Shield className="icon" />
                  <div className="audit-details">
                    <div className="audit-firm">{audit.firm}</div>
                    <div className="audit-date">{audit.date}</div>
                    <div className="audit-findings">{audit.findings}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="info-section">
            <h3>Exploits</h3>
            <div className="exploits-list">
              {protocolData.exploits.map((exploit, index) => (
                <div key={index} className="exploit-item">
                  <AlertTriangle className="icon" />
                  <div className="exploit-details">
                    <div className="exploit-date">{exploit.date}</div>
                    <div className="exploit-type">{exploit.type}</div>
                    <div className="exploit-amount">${exploit.amount}</div>
                    <div className="exploit-description">{exploit.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtocolPage; 